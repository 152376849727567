






























































































































































































import { Validator } from '@/constant/Mixins';
import Component from 'vue-class-component';
import { Mixins, Watch } from 'vue-property-decorator';
import filters from '@/constant/Filters';
import { Action, State } from 'vuex-class';
import { ProductAdditionalInterface, ProductDataModel, ProductFeatureInterface, ProductFeatureModel, ProductSummaryModel } from '@/store/product/types';
import { INSURANCES, PRODUCT_INSTALLMENT_MONTH_DIFF } from '@/constant/Enums';
import FormCompareMobilModel, { CompareContentModel, ProductMobilState } from '@/store/product/mobil/types';
import { ServerResponse } from '@/services/response.types';

interface TableItem {
  id: number;
  name: string;
  provider_name: string;
  quotation_id: string;
  compare: string;
  sum_insured: string;
  rating: string;
  workshop: { local: number; others: number; };
  total: number;
  annual_price: number;
  type_insurance: string;
  installment: {
    duration: number;
    unit: string;
    nominal: number;
  }[];
  additional_fee: { label: string; nominal: number | string | ProductAdditionalInterface[], additional?: any[]; }[];
  features: {
    towing_car: boolean;
    Replacement_car: boolean;
    send_policy: string;
  };
  brand: string;
  list_feature: ProductFeatureInterface[];
  list_additionals: any[];
  _showDetails: boolean;
}

interface Comparisons {
  label: string;
  product1?: TableItem;
  product2?: TableItem;
  product3?: TableItem;
}

@Component({
  name: 'listMobil',
  components: {
    ProductDetail: () => import('@/components/ProductDetail.vue'),
    formMobil: () => import('@/components/formCompare/compareCar.vue'),
    callMe: () => import("@/components/FormCallMe.vue")
  },
  filters,
})
export default class listMobil extends Mixins(Validator) {
  @Action('GetData', { namespace: 'product/mobil' }) GetData!: (form?: FormData | { sort: string; }) => Promise<ServerResponse>;
  @Action('CompareProductAction', { namespace: 'product/mobil' }) CompareProductAction!: (quotation_ids: string[]) => Promise<ServerResponse>;
  @Action('GetSummary', { namespace: 'product/mobil' }) GetSummary!: (quotation_ids: string) => Promise<ServerResponse>;

  @State('onLoad', { namespace: 'product/mobil' }) onLoad!: boolean;
  @State('product', { namespace: 'product/mobil' }) product!: ProductDataModel;
  @State('formCompare', { namespace: 'product/mobil' }) form!: FormCompareMobilModel;
  @State('summary', { namespace: 'product/mobil' }) summary!: ProductSummaryModel;
  @State((state: ProductMobilState) => state.listCompare, { namespace: 'product/mobil' }) listCompare!: CompareContentModel[];

  @Watch('OnComparing') OnComparingChange(value: boolean) {
    if (value) document.body.classList.add('overflow-hidden');
    else document.body.classList.remove('overflow-hidden');
  }

  public type_insurance = INSURANCES.filter((item) => item.value === this.form?.type_insurance)[0]?.text;
  public compareLimit = 3;
  public comparisons: TableItem[] = [];
  public onLoadCompare = false;
  public selected: TableItem = null;

  // public get listCompare(): CompareContentModel[] {
  //   let list = this.stateListCompare
  //   return list||[]
  // }
  // public set listCompare(v : CompareContentModel[]) {
  //   this.stateListCompare = v;
  // }


  public get fieldsCompare(): any[] {
    let fields = [
      {
        class: 'side-header compare-product-item',
        key: 'label',
        stickyColumn: false,
      },
    ];
    this.comparisons.forEach((item, i) =>
      fields.push({
        class: 'compare-product-item',
        key: `produk_${i + 1}`,
        stickyColumn: false,
      })
    );
    return fields;
  }

  public get comparisonsAside(): Comparisons[] {
    let rows = [
      { label: 'Label', key_ref: ['brand&provider_name'] },
      { label: 'Produk', key_ref: ['name'] },
      { label: 'Harga Mobil', key_ref: ['sum_insured'] },
      { label: 'Harga Premi', key_ref: ['annual_price'] },
      // { label: 'Rating', key_ref: ['rating'] },
      { label: 'Tipe Asuransi', key_ref: ['coverage'] },
      { label: 'Mobil Derek', key_ref: ['features', 'towing_car'] },
      { label: 'Mobil Pengganti	', key_ref: ['features', 'Replacement_car'] },
      { label: 'Durasi Pengiriman Polis', key_ref: ['features', 'send_policy'] },
      { label: 'Fitur', key_ref: ['feature'] },
      { label: 'Perlindungan Tambahan', key_ref: ['additional'] },
      // { label: 'Action', key_ref: [] },
    ].map((cols: any) => {
      this.comparisons.forEach((item, i) => (cols[`product_${i + 1}`] = { ...item, ...this.listCompare.filter((v, i) => Number(v.product.product_id) === Number(item.id))[0]?.product || {} }));
      return cols;
    });

    return rows;
  }

  public showDetail = false;
  public showCallMe = false;
  public OnComparing = false;

  public selectedOrder = 0;
  public SortByOptions = [
    {
      text: 'The Lowest',
      value: 'lowest',
    },
    {
      text: 'The Highest',
      value: 'highest',
    },
    {
      text: 'Company A-Z',
      value: 'a-z',
    },
    {
      text: 'Company Z-A',
      value: 'z-a',
    },
  ];

  public fields = [
    {
      class: 'col-3',
      key: 'annual_price',
      label: 'Annual Price',
      sortable: false,
      _showDetails: true,
    },
    {
      class: 'col-3',
      key: 'installment',
      label: 'Installment',
    },
    {
      class: 'col-3',
      key: 'additional_fee',
      label: 'Additional Fee',
      sortable: false,
    },
    {
      class: 'col-3',
      key: 'features',
      label: 'Insurance Features and Extra',
      sortable: false,
      headspan: [
        {
          label: 'Towing Car',
        },
        {
          label: 'Replacement Car',
        },
        {
          label: 'Duration Send Policy',
        },
      ],
    },
  ];

  // [NOTE]: _showDetails must exist in each items
  public get items(): TableItem[] {
    return this.product.listdata.map((item) => {

      let admninistration = Number(item.admin_fee) + Number(item.handling_fee);
      let additionalFee = (Number(item.additional_premi) > 0 ? item.additional_premi : 0);
      let totalPremium = Number(item.discount_premium) + admninistration + additionalFee;

      return {
        id: Number(item.product_id),
        name: String(item.product_name),
        provider_name: String(item.provider_name),
        quotation_id: item.quotation_id,
        sum_insured: item.sum_insured,
        rating: item.rate_basic,
        type_insurance: 'Mobil',
        coverage: INSURANCES.filter((v) => v.value === Number(item.coverage_type))[0].text || "Not Defined!",
        compare: item.compare,
        total: Number(item.workshop),
        workshop: item.workshop,
        annual_price: totalPremium,
        installment: PRODUCT_INSTALLMENT_MONTH_DIFF.map((month) => {
          return {
            duration: month,
            unit: 'months',
            nominal: totalPremium / month,
          };
        }),
        additional_fee: [
          {
            label: 'Admninistration',
            nominal: admninistration,
          },
          {
            label: 'Additional',
            nominal: additionalFee,
          },
        ],
        features: {
          towing_car: Number(item.col1) === 1,
          Replacement_car: Number(item.col2) === 1,
          send_policy: item.col3,
        },
        list_feature: [],
        list_additionals: [],
        brand: item.logo,
        _showDetails: true,
      };
    });
  }

  public created(): void { }
  public mounted(): void {
    window.onresize = () => {
      if (this.comparisons.length > 0) this.OnCollapseShow(`compare-product-img`);
      if (this.OnComparing) this.OnCollapseShow(`comparer-product-img`);
    };
  }

  /**
   * OnCompare
   */
  public OnCompare(item: TableItem) {
    this.comparisons.push(item);
    this.updateListCompare(item);
  }

  /**
   * OnDeleteCompare
   */
  public OnDeleteCompare(item: TableItem) {
    this.comparisons = this.comparisons.filter((v) => v.id !== item.id);
    this.updateListCompare(item);
  }

  /**
   * updateListCompare
   */
  public updateListCompare(item: TableItem) {
    this.listCompare = this.comparisons.map((item) => {
      let content = new CompareContentModel();
      content.product = this.product.listdata.filter((product) => Number(product.product_id) === item.id)[0];
      return content;
    });
  }

  /**
   * GetRefferenceCompateAttribute
  */
  public GetRefferenceCompateAttribute(item: { label: string; key_ref: any[]; product_1?: TableItem; product_2?: TableItem; product_3?: TableItem; }, product_ref: string) {
    let value: any = item[product_ref];
    item.key_ref.forEach((key) => {
      if (key instanceof Array) value = value[key[0]];
      else if (typeof key === 'string') {
        if (key.indexOf('&') >= 0) {
          value = '';
          key.split('&').forEach((k, i) => {
            let refVal: string = item[product_ref];
            refVal = refVal[k] || '';

            let isHTML = refVal.indexOf('http') >= 0;
            if (isHTML) value += `<img ref="product-logo" src="${refVal}" class="img-thumbnail d-block mx-auto" style="height: 100px;" />`;
          });
        } else value = value[key];
      }
    });

    if (typeof value === 'boolean') return value ? `<i class="fa fa-check h4 text-success" aria-hidden="true"></i>` : `<i class="fa fa-times h4 text-danger" aria-hidden="true"></i>`;
    else if (Number(value) >= 1000) return this.formatCurrency(value);
    else if (value instanceof Array) return "<ul>" + value.map((item: ProductFeatureModel) => `<li>${item.detail_ind}</li>`).join("") + "</ul>";
    else return item.key_ref.length > 0 ? value : null;
  }

  /**
   * OnClickNext
   */
  public OnClickNext(item: TableItem) {
    this.$router.push({ name: this.$route.meta ? this.$route.meta.route_detail_name : '', query: { q: item.quotation_id } });
  }

  /**
   * OnClickCompare
   */
  public OnClickCompare() {
    this.onLoadCompare = true;
    this.CompareProductAction(this.comparisons.map((item) => item.quotation_id)).then((res) => {
      this.OnComparing = true;
      // this.comparisonsAside = this.comparisonsAside.map((i)=>{...i, })
    }).finally(() => this.onLoadCompare = false);
  }

  /**
   * OnClickDetail
   */
  public OnClickDetail(item: TableItem) {
    this.GetSummary(item.quotation_id).then((res) => {
      if (res.data) this.showDetail = true;
    });
  }

  /**
   * onClickCallMe
   */
  public onClickCallMe(item: TableItem) {
    this.selected = item;
    this.showCallMe = !this.showCallMe;
  }

  /**
   * OnCollapseShow
   */
  public OnCollapseShow(target: string) {
    let imgs = this.$refs[target] as HTMLImageElement[];
    if (imgs) {
      let height = imgs[imgs.length - 1].clientHeight;
      imgs.forEach((img: HTMLImageElement) => {
        img.style.height = `${height}px`;
      });
    }
  }
}
